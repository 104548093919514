export const formatUSPhoneNumber = (value: string, country?: string) => {
  if (country !== "US") return value;
  const cleaned = value.replace(/\D/g, "");

  let formattedNumber = "";

  if (cleaned.length > 0) formattedNumber = `(${cleaned.substring(0, 3)}`;
  if (cleaned.length >= 4) formattedNumber += `) ${cleaned.substring(3, 6)}`;
  if (cleaned.length >= 7) formattedNumber += `-${cleaned.substring(6, 10)}`;

  return `${formattedNumber}`;
};

export const displayUSPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return "";
  let cleaned = phoneNumber.replace(/\D/g, "");

  if (cleaned.length > 10 && cleaned.startsWith("1")) {
    cleaned = cleaned.slice(1);
  }

  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
};
